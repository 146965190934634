@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  padding-left: 15px;
}

a {
  text-decoration: none;
  color: #3f51b5;
}

img,
svg {
  max-width: 100%;
  max-width: -webkit-fill-available;
}

body {
  font-family: Inter, sans-serif;
}

.MuiSvgIcon-colorSecondary {
  color: #204ECF !important;
}

.MuiTab-root {
  padding: 12px 15px !important;
}

.icon-button-hover .MuiButton-root:hover {
  background-color: transparent !important;
}

.MuiButton-root .MuiCircularProgress-root {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.MuiButton-root .MuiSvgIcon-root {
  display: block;
}

.fullMinHeight {
  min-height: 100%;
}

.MuiList-root.MuiMenu-list.MuiList-padding {
  min-width: 160px;
}

.overflow-visible {
  overflow: visible !important;
}

.table-overflow {
  overflow-x: auto;
}

.table-overflow .MuiTableCell-head {
  vertical-align: baseline;
}

.pointer-cursor {
  cursor: pointer !important;
}

.media-image {
  height: 200px;
  position: relative;
  overflow: hidden;
}

.media-image img {
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.media-image .media-overlay {
  position: absolute;
  top: 200px;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .4s ease-in-out;
}

.media-image:hover .media-overlay {
  top: 0;
  z-index: 99;
}

.overflowY-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.MuiPopover-paper {
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05) !important;
  border-radius: 6px !important;
  border: 1px solid #E4E6EF;
}

.MuiPopover-paper.user-setting-menu {
  margin: -40px 0 0 18px;
  width: 240px;
  left: 40px !important;
}

.MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-switchHeader .MuiPickersCalendarHeader-iconButton {
  padding: 5px;
  max-width: 100%;
  width: 36px;
}

.MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-switchHeader .MuiPickersSlideTransition-transitionContainer>p {
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-daysHeader .MuiPickersCalendarHeader-dayLabel {
  font-size: 14px;
  /* width: 42px; */
}

.MuiPickersBasePicker-pickerView .MuiPickersSlideTransition-transitionContainer .MuiPickersDay-daySelected {
  background-color: #204ECF;
}

.MuiPickersBasePicker-pickerView .MuiPickersSlideTransition-transitionContainer .MuiPickersDay-day .MuiIconButton-label>p {
  font-size: 15px;
  color: #666666;
  font-weight: 500;
}

.MuiPickersBasePicker-pickerView .MuiPickersSlideTransition-transitionContainer .MuiPickersDay-current .MuiIconButton-label>p {
  color: #204ECF;
}

.MuiPickersBasePicker-pickerView .MuiPickersSlideTransition-transitionContainer .MuiPickersDay-daySelected .MuiIconButton-label>p {
  color: #fff;
}

body .MuiTabPanel-root {
  padding: 20px 0 0 0;
}

.react-tel-input {
  font-family: "Inter, sans-serif";
  font-size: 14px;
  border: 1px solid #EEF6FF;
}

.react-tel-input:hover {
  border: 1px solid #1C6DD0;
}

.react-tel-input .form-control {
  width: 100%;
  background-color: #F3F4F6;
  border: none;
  height: 48px;
  color: currentColor;
}

.react-tel-input .flag-dropdown {
  border-color: #F3F4F6;
}

.MuiCheckbox-colorPrimary,
.MuiCheckbox-colorSecondary {
  width: 40px;
}

.MuiCheckbox-colorPrimary.Mui-checked,
.MuiCheckbox-colorSecondary.Mui-checked,
.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  color: #204ECF;
  width: 40px;
}

.MuiCheckbox-root.Mui-checked .MuiIconButton-label:before {
  display: none;
}

.danger {
  color: #DD1010;
}

.logo-container {
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  width: 160px;
  height: 160px;
  overflow: hidden;
}

/* Signature-pad-Styling */
.signCanvas {
  width: 100%;
  height: 150px;
}

/* Signature-pad-Styling  */
.publicSignCanvas {
  width: 100%;
  height: 300px;
}

/* Drop-in-Payment-Styling */
.zoid-outlet {
  width: 100% !important;
}

.braintree-option {
  min-height: 80px !important;
}

.braintree-option,
.braintree-sheet,
.braintree-sheet__header,
.braintree-upper-container:before {
  border: none !important;
  background-color: #ffffff !important;
}

.braintree-options-list {
  background-color: #f3f4f6 !important;
}

.braintree-option:first-child,
.braintree-sheet__container,
.braintree-large-button {
  margin-bottom: 2rem;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__hosted-field {
  border: none !important;
  background-color: #f3f4f6 !important;
}

.braintree-sheet__header .braintree-sheet__header-label {
  padding-bottom: 10px;
}

/* custom-scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #E0E0E0;
}

/* dialog-css-override */
.dialogBg {
  background-color: #ffffff !important;
  border: none !important;
  padding: 0 !important;
}

.dialogBg .MuiTypography-body1 {
  font-size: 15px !important;
}

.schedulerTableCell {
  border: 1px solid #E4E6EF !important;
  /* width: 200px !important; */
  height: 200px !important;
}

.selectorClass .MuiSvgIcon-root>path {
  display: none !important;
}

.selectorClass .MuiSvgIcon-root {
  background-image: url(/static/media/chevron-down.3d0bf646.svg) !important;
  background-size: auto !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  max-width: 100% !important;
  width: 20px !important;
}

.input-dollar-class .MuiInputBase-input {
  background-image: url(/static/media/dollar-sign.2f6a3a44.svg) !important;
  background-size: auto !important;
  background-position: right center !important;
  padding-right: 25px;
  background-repeat: no-repeat !important;
}

.custom-num-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.custom-num-input input[type=number] {
  -moz-appearance: textfield;
}

.selectorClassTwo {
  border: 1px solid #F3F4F6 !important;
  background-color: #F3F4F6 !important;
  border-radius: 4px !important;
  max-width: 330px !important;
}

.selectorClassTwoError {
  border: 1px solid red !important;
  background-color: #F3F4F6 !important;
  border-radius: 4px !important;
  max-width: 330px !important;
}

.selectorClassTwo>div:first-of-type {
  border: 1px solid #F3F4F6 !important;
  background-color: #F3F4F6 !important;
  border-radius: 4px !important;
  min-height: 48px !important;
}

.selectorClassTwo svg,
.selectorClassTwo span {
  color: #202020 !important;
}

.diagnosesSelectorClass {
  min-width: 100% !important;
}

/* Dashboard-charts-styling */
.chartContainer>div>div {
  height: 300px !important;
}

.chartContainer svg {
  height: 320px !important;
  -webkit-transform: scale(1.25) !important;
          transform: scale(1.25) !important;
}

.pieChart2Container>div>div {
  height: 200px !important;
}

.pieChart2Container svg {
  height: 320px !important;
  -webkit-transform: scale(1.2) !important;
          transform: scale(1.2) !important;
}

.chartContainerSmall>div>div {
  height: 180px !important;
  overflow: hidden !important;
}

.chartContainerSmall svg {
  height: 160px !important;
  -webkit-transform: scale(3) !important;
          transform: scale(3) !important;
}

.practice-bar-chart-container text,
.barChart3Container text,
.practice-users-chart-container text,
.practice-users-roles-container text {
  color: #ffffff !important;
  fill: #ffffff !important;
}

.practice-bar-chart-container .highcharts-color-0,
.barChart2Container .highcharts-color-0,
.barChart3Container .highcharts-color-0,
.practice-bar-chart-container .highcharts-legend-item,
.barChart2Container .highcharts-legend-item,
.barChart3Container .highcharts-legend-item {
  display: none !important;
}

.practice-bar-chart-container .highcharts-axis-labels text,
.barChart2Container .highcharts-axis-labels text,
.barChart3Container .highcharts-axis-labels text,
.practice-users-chart-container .highcharts-axis-labels text,
.practice-users-roles-container .highcharts-axis-labels text,
.barChart6Container .highcharts-axis-labels text {
  font-weight: 600 !important;
}

.practice-users-chart-container .highcharts-grid-line,
.practice-users-roles-container .highcharts-grid-line {
  display: none !important;
}

.practice-users-chart-container .highcharts-axis,
.practice-users-roles-container .highcharts-axis,
.practice-bar-chart-container .highcharts-axis {
  display: none !important;
}

.pieChart2Container text {
  color: #acacac !important;
  fill: #acacac !important;
}

.recent-activity-timeline .MuiTimelineItem-missingOppositeContent:before {
  display: none;
}

.recent-activity-timeline p {
  color: #7E8299;
}

.recent-activity-timeline .MuiTimelineDot-root {
  border-width: 4px;
}

.recent-activity-timeline .MuiTimelineConnector-root {
  background: #E5EAEE;
}

.overflowVisible {
  overflow: visible !important;
}

.media-inner-image {
  padding: 0px !important;
  height: 150px !important;
}

.media-inner-image>img {
  object-fit: fill !important;
}

.dropzone-area-preview-list .MuiGrid-spacing-xs-8 {
  margin: 0 !important;
  padding: 0 20px !important;
}

.whiteColor {
  color: #ffffff !important;
}

.topMargin {
  top: -24px !important;
}

.highcharts-container {
  width: auto !important;
}

.card-chart {
  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(56, 71, 109, 0.09);
  border-radius: 8px;
  position: relative;
}

.areaBloodPressureChart .highcharts-container {
  /* transform: translate(-60px, 221px) scale(1.5) !important; */
  -webkit-transform: translate(-32px, 80px) scale(1.3) !important;
          transform: translate(-32px, 80px) scale(1.3) !important;
  overflow: hidden;
  margin-top: 24px;
  padding-top: 20px;
}

.areaChartContainer>div>div {
  max-height: 280px !important;
  width: 100% !important;
}

.areaChartContainer svg {
  max-height: 200px !important;
  -webkit-transform: scale(1.5) !important;
          transform: scale(1.5) !important;
  width: 100% !important;
}

.bloodPressure-measurement {
  position: absolute;
  left: 30px;
  /* top: 135px; */
  color: #CA6B6E;
  z-index: 1;
}

.bloodPressure-measurement .measure-unit {
  font-size: 14px;
  color: #464E5F;
  margin-left: 10px;
}

.heartRate-measurement {
  position: absolute;
  left: 30px;
  /* top: 135px; */
  color: #1BC5BD;
  z-index: 1;
}

.heartRate-measurement .measure-unit {
  font-size: 14px;
  color: #464E5F;
  margin-left: 10px;
}

.measure-frequency {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #ffff;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
}

.primary {
  background-color: #03CC83;
}

.danger-bg {
  background-color: #DD1010;
}

.card-box-shadow {
  box-shadow: 0px 4px 30px rgba(56, 71, 109, 0.09) !important;
  border-radius: 8px !important;
}

.macro-ul {
  border-radius: 8px;
  background-color: #F3F4F6;
  padding-left: 0;
}

.macro-ul li {
  padding: 10px;
  list-style: none;
}

.macro-ul li:hover {
  box-shadow: 0px 4px 30px rgba(56, 71, 109, 0.09);
  background-color: #ffffff;
}

.word-wrap {
  word-wrap: break-word;
}

.z-index {
  z-index: 1;
}

.overflow-auto {
  overflow: auto;
}

.patient-information-form .MuiInputLabel-formControl {
  position: relative;
}

.MuiDropzoneSnackbar-message {
  word-break: break-word;
}

.word-break {
  word-break: break-all;
}

.mt-10px {
  margin-top: -10px;
}

/* masonry-css */
.masonry-container {
  -moz-column-count: 2;
  -moz-column-gap: 1%;
  -moz-column-width: 48%;
  -webkit-column-count: 2;
  -webkit-column-gap: 1%;
  -webkit-column-width: 48%;
  column-count: 2;
  grid-column-gap: 1%;
  column-gap: 1%;
  column-width: 48%;
}

.masonry-box {
  margin-bottom: 20px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
}

.appointment-view-list .MuiTable-root tbody tr td:first-child {
  max-width: 200px;
}

.appointment-view-list .MuiTable-root tbody tr .custom-cell {
  padding: 10px;
}

.card-box {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.card-overlay {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 350ms cubic-bezier(0, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(62, 62, 62, 0.7);
}

.card-overlay .del-icon,
.card-overlay .del-icon:hover {
  background-color: white;
}

.card-box:hover .card-overlay {
  top: 0;
}

.card-img {
  max-width: 100%;
  height: 100%;
  max-height: 280px;
  overflow: hidden;
}

.card-img>img {
  object-fit: contain;
  height: 100%;
}

.py-0 {
  padding: 0 !important;
  padding-bottom: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.h-24 {
  height: 24px !important;
}

.vitals-header>th>div>h5 {
  text-transform: capitalize !important;
}

.patient-add-form .MuiInputLabel-formControl {
  position: relative;
}

.vitals-column .MuiTableCell-root {
  height: 45px !important;
}

.vitals-column .MuiTableCell-root .MuiBox-root>.MuiTypography-noWrap {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.copay-type-value {
  position: relative;
}

.copay-type-value .MuiInputBase-input {
  -webkit-appearance: none;
          appearance: none;
  padding-right: 25px;
}

.copay-type-value .MuiInputBase-input::-webkit-outer-spin-button,
.copay-type-value .MuiInputBase-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.copay-type-value .MuiTypography-h3 {
  position: absolute;
  top: 29px;
  right: 12px;
}

.disable-icon {
  opacity: 0.3;
  pointer-events: none;
}

.toggle-main .MuiSwitch-thumb {
  background-color: #204ECF !important;
}

.dialog-box {
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.MuiSkeleton-text {
  height: 30px !important;
}

.makeStyles-chartModalBox-137 .MuiSkeleton-text {
  -webkit-transform: scale(1, 1) !important;
          transform: scale(1, 1) !important;
}

.skelton-label-margin {
  margin-top: -8px;
  margin-bottom: 12px;
}

.skelton-input {
  margin-bottom: 8px;
  padding-bottom: 16px;
}

.align-center {
  align-items: center !important;
}

/* form-builder */
.formBuilder-stepLabel .MuiStepIcon-root {
  color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 50px;
}

.formBuilder-stepLabel .MuiStepIcon-root .MuiStepIcon-text {
  fill: rgba(0, 0, 0, 0.38);
}

.formBuilder-stepLabel .MuiStepIcon-root.MuiStepIcon-active {
  color: #204ECF;
  border: 1px solid #ffffff;
}

.formBuilder-stepLabel .MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text {
  fill: #ffffff;
}

/* edge-eye-icon-bug */
::-ms-reveal {
  display: none !important;
}

.date-box-wrap .MuiTypography-colorTextPrimary {
  margin-top: -9px;
  margin-bottom: 5px;
  color: #202020;
  font-size: 14px;
  -webkit-transform: scale(1);
          transform: scale(1);
  font-weight: 500;
  -webkit-transform-origin: top left;
          transform-origin: top left;
}

.date-box .btn-icon {
  min-width: 35px;
  min-height: 41px;
}

.date-input-box {
  height: 40px;
  padding: 0 10px;
  min-width: 100px;
  display: flex;
  align-items: flex-start;
}

.date-input-box .MuiFormControl-marginNormal {
  margin-top: -5px;
}

.schedule-modal .MuiDialog-paper {
  overflow-y: inherit;
}

.cke_path {
  display: none !important;
}

.ach-hover:hover {
  cursor: pointer;
}

.ach-hover:hover .MuiTypography-root {
  font-weight: 600 !important;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.text-underline {
  text-decoration: underline !important;
}

.border-bottom {
  border-bottom: 1px solid #F3F6F9;
}

.rotate-180 {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}

.rotate-Icon {
  transition: 0.9s linear !important;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.rotate-Icon.to-180 {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}

.log-class {
  border: 10px solid red;
}

.billing-inputs .MuiFormControl-marginNormal {
  padding-bottom: 0 !important;
  margin-top: 0 !important;
}

.billing-selector .MuiFormControl-root .MuiAutocomplete-inputRoot {
  height: auto !important;
  min-height: auto !important;
}

.billing-selector .MuiFormControl-marginNormal {
  padding-bottom: 0 !important;
  margin: 0 !important;
}

.billing-tabs .MuiTabPanel-root {
  padding-top: 0 !important;
}

.claim-box .MuiOutlinedInput-root {
  background-color: #E5E7EB !important;
}

.header-selector .MuiSvgIcon-root path {
  display: none !important;
}

.header-selector .MuiSvgIcon-root {
  background-image: url(/static/media/chevron-down.3d0bf646.svg) !important;
  background-size: auto !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

/* media queries */

@media(max-width : 1260px) {
  .MuiPickersBasePicker-pickerView .MuiPickersSlideTransition-transitionContainer .MuiPickersDay-day {
    height: 36px;
    width: 36px;
  }
}

@media(max-width : 960px) {
  .appointment-action-btn {
    flex-direction: column;
  }

  .masonry-container {
    -moz-column-count: 1;
    -moz-column-gap: 1%;
    -moz-column-width: 100%;
    -webkit-column-count: 1;
    -webkit-column-gap: 1%;
    -webkit-column-width: 100%;
    column-count: 1;
    grid-column-gap: 1%;
    column-gap: 1%;
    column-width: 100%;
  }
}

@media(max-width : 768px) {
  .facility-registration-form {
    flex-direction: column;
  }

  .facility-registration-form .facility-menu-navbar {
    max-width: 100%;
    position: relative;
    top: 0;
  }

  .patient-add-form .MuiInputLabel-formControl {
    position: relative;
    top: 0;
    margin-bottom: 0.875rem;
  }

  .profile-hero-patient {
    flex-direction: column;
  }
}

.csvLink {
  color: inherit;
}

.ck-content {
  height: 320px;
}

.not-link {
  text-decoration: none !important;
}

.MuiIconButton-root {
  max-width: 100%;
  min-width: auto !important;
}

.MuiIconButton-sizeSmall {
  max-width: 100%;
  min-width: 20px !important;
  height: auto !important;
}

.timePickerIcon {
  position: relative;
  border-radius: 4px;
  background-color: #F3F4F6;
}

.timePickerIcon .MuiInput-input {
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  box-sizing: border-box;
}

.billing-box {
  color: #204ECF;
  cursor: pointer;
}

.billing-box .MuiTypography-root {
  padding-left: 5px;
}

.claim-error-dialog {
  max-width: 600px;
  margin: auto;
}

.step-icon .MuiStepLabel-iconContainer {
  max-width: 100%;
  width: 40px;
}

.payment-input .MuiOutlinedInput-root {
  background-color: #fff;
}

.li-hover:hover {
  box-shadow: 0px 4px 30px rgba(56, 71, 109, 0.09);
  padding: 2px;
}

.table-loader-row td:first-child {
  padding-left: 10px !important;
}

.MuiTableRow-head {
  vertical-align: baseline !important;
}

.my-2 {
  margin-top: 1px;
  margin-bottom: 1px;
}

.pt-location .MuiFormControl-marginNormal {
  margin: 0;
  padding: 0;
}

.pt-location .MuiFormControl-marginNormal .MuiFormControl-root .MuiAutocomplete-inputRoot {
  height: 40px;
  margin-top: 5px;
}

.pointer-cursor:hover{
  cursor: pointer;
}
